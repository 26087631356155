import { createSlice, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { inventoryFetchComplete } from "actions/sharedActions";
import { lotsSelector } from "./lots";

export const NOTES_PER_PAGE = 10;

const inventoryNotesAdapter = createEntityAdapter({
  selectId: note => note.entityId
});

const adminUserNotesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.createdOn < b.createdOn
});

const consignorNotesAdapter = createEntityAdapter({
  selectId: note => note.id
});

export const inventoryNotesSelector = inventoryNotesAdapter.getSelectors(
  state => state.notes.inventory
);

export const adminUserNotesSelector = adminUserNotesAdapter.getSelectors(
  state => state.notes.admin
);

export const consignorNotesSelector = adminUserNotesAdapter.getSelectors(
  state => state.notes.consignor
);

const initialState = {
  isLoading: true,
  isSaving: false,
  // multiple entities with a single note
  inventory: {
    ...inventoryNotesAdapter.getInitialState()
  },
  // multiple notes on a single entity
  admin: {
    ...adminUserNotesAdapter.getInitialState(),
    hasNew: false,
    page: 0,
    total: 0
  },
  consignor: {
    ...consignorNotesAdapter.getInitialState()
  }
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    setSavingStatus: (state, action) => {
      state.isSaving = action.payload;
    },
    // inventory concern
    setInventoryNotes: (state, action) => {
      inventoryNotesAdapter.setAll(state.inventory, action);
      state.isLoading = false;
    },
    addInventoryNote: (state, action) => {
      inventoryNotesAdapter.addOne(state.inventory, action);
    },
    updateInventoryNote: (state, action) => {
      inventoryNotesAdapter.updateOne(state.inventory, action);
    },
    deleteInventoryNote: (state, action) => {
      inventoryNotesAdapter.removeOne(state.inventory, action);
    },
    // admin user concern
    setAdminNotes: (state, action) => {
      adminUserNotesAdapter.setAll(state.admin, action.payload.notes);
      state.admin.total = action.payload.total ?? 0;
      state.admin.page = action.payload.page ?? 0;
      state.admin.hasNew = false;
      state.isLoading = false;
    },
    setHasNew: (state, action) => {
      state.admin.hasNew = action.payload;
    },
    updateAdminNote: (state, action) => {
      adminUserNotesAdapter.updateOne(state.admin, action);
    },
    resetAdminNotes: state => {
      adminUserNotesAdapter.setAll(state.admin, []);
      state.admin.total = 0;
      state.admin.page = 0;
      state.isLoading = true;
      state.admin.hasNew = false;
    },
    //consignor
    addConsignorNotes: (state, action) => {
      consignorNotesAdapter.addMany(state.consignor, action);
      state.isLoading = false;
    },
    addConsignorNote: (state, action) => {
      consignorNotesAdapter.addOne(state.consignor, action);
    },
    deleteConsignorNote: (state, action) => {
      consignorNotesAdapter.removeOne(state.consignor, action);
    },
    removeConsignorNotes: (state, action) => {
      const consignorNotes = consignorNotesAdapter.getSelectors().selectAll(state.consignor);

      consignorNotesAdapter.removeMany(
        state.consignor,
        filterNotes(consignorNotes, action.payload.dealershipId, action.payload.entityId).map(
          note => note.id
        )
      );
    }
  },
  extraReducers: builder => {
    builder.addCase(inventoryFetchComplete, (state, action) => {
      if (action?.payload.notes?.length > 0) {
        inventoryNotesAdapter.setAll(state.inventory, action.payload.notes);
        state.isLoading = false;
      }
    });
  }
});

const getConsignorNotes = state =>
  consignorNotesAdapter.getSelectors().selectAll(state.notes.consignor);
const getEntity = (state, entityId) => lotsSelector.selectById(state.lots, entityId);
export const selectConsignorNotes = createSelector(
  getConsignorNotes,
  getEntity,
  (notes, entity) => {
    if (!entity) {
      return [];
    }

    return filterNotes(notes, entity.consignorId, entity.id);
  }
);

function filterNotes(notes, dealershipId, entityId) {
  return notes.filter(note => note.dealershipId === dealershipId && note.entityId === entityId);
}

export default notesSlice;

export const regEx = /[^\w\s`~!@#$%^&*()_+-='";:?\\.,<>]/g;

export function filterOutBadCharacters(text) {
  return text.replace(regEx, "").trim();
}
